import axios from "axios";
import { stubFalse } from "lodash";
import {
  API_LOCAL_IP,
  API_SERVICE_BACKEND,
  API_SERVICE_BACKEND_2,
} from "src/config";
import { deepCopy } from "src/utils/deep-copy";
// import { companies, company } from './data';
import AllLeadData from "../../data/data.json";
import UserAllCampaigns from "../../data/allCampaignswithMetadata.json";
import DashboardChartDataStatic from "../../data/dashboard-chartdata.json";
import leadsTotalData from "../../data/leadsTotalData.json";

class JobsApi {
  getCampaignDetails = async (campaignId, isSaveAsDraft, isCopy = "") => {
    // const url =
    //   isSaveAsDraft === "true"
    //     ? `${API_SERVICE_BACKEND}/filterSaveAsDraftCampaigns`
    //     : `${API_SERVICE_BACKEND}/filterCampaigns`;
    // const res = await axios.get(url, {
    //   params: { _id: campaignId, isCopy: isCopy },
    // });
    // if (res.status === 200) {
    //   return res.data;
    // } else {
    //   throw new Error(res.data);
    // }
    return UserAllCampaigns?.data?.filter((data)=>data?._id===campaignId)
  };

  getCampaignStats = async (campaignId) => {
    return {
      leadsGen: 6289,
      totalEmailed: 5363,
      emailSentStatus: {
          blocked: 2551,
          delivered: 2812
      },
      emailsOpened: 1534,
      meetings: 0,
      conversations: 0,
      totalResponses: 0,
      responsesPerUser: [],
      totalClicks: 0,
      clicks: 0
  }
  };

  getDashBoardStats = async (campaignId) => {
    const res = await axios.get(
      `${API_SERVICE_BACKEND}/getDashBoard/${campaignId}`
    );
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(res.data);
    }
  };

  getUserCampaigns = async (email, search = "", page = 0, limit = 0, status = "all") => {
    // console.log(email)
    // try {
    //   try {
    //   const result = await axios.get(
    //     "https://ipapi.co/json",
    //   )
    //   const addEntry = await axios.post(`${API_SERVICE_BACKEND}/getipaddress`, {
    //     ...result?.data,email
    //   });    
    // } catch (error) {
    //     console.log(error.message)
    // }
    //   const res = await axios.get(
    //     `${API_SERVICE_BACKEND}/userCampaigns/${email}?search=${encodeURIComponent(search)}&page=${page}&limit=${limit}&status=${status}`
    //   );

    //   if (res.status === 200) {
    //     return res.data;
    //   } else {
    //     throw new Error(res.data);
    //   }
    // } catch (error) {
    //   console.log("error", error);
    // }
    return UserAllCampaigns;
  };

  getCampaignLeads = async (
    campaignId,
    page,
    limit = 100,
    abortController,
    email,
    sortOptions,
    exportBool
  ) => {
    // console.log(campaignId)
    console.warn(sortOptions);
    const res = await axios.get(
      // `http://192.168.18.60:8080/api/v1/main/campaignLeads/${campaignId}`,
      `${API_SERVICE_BACKEND}/campaignLeads/${campaignId}`,
      {
        params: {
          email,
          page,
          limit,
          sortJSON: encodeURI(JSON.stringify(sortOptions)),
          exportBool,
        },
        signal: abortController?.signal,
      }
    );
    if (res.status === 200) {
      return [...res.data.leads, { count: res.data.count }];
    } else {
      throw new Error(res.data);
    }
  };

  getUserSaveAsDraftCampaigns = async (email, search = "",page,limit) => {
    // console.log(email)
    return;
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/userSaveAsDraftCampaigns/${email}?search=${encodeURIComponent(
          search
        )}&page=${page}&limit=${limit}`
      );

      if (res.status === 200) {
        return res.data.data;
      } else {
        throw new Error(res.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  getCampaignLeadsV2 = async (
    campaignId,
    page,
    limit = 100,
    abortController,
    email,
    sortOptions,
    exportBool,
    search,
    dateSelect,
    isEmailSent=false,
    stage="",
    isCounts=true
  ) => {

    // const payload = {
    //   email,
    //   page,
    //   limit,
    //   isEmailSent,
    //   sortJSON: encodeURI(JSON.stringify(sortOptions)),
    //   exportBool,
    //   search,
    //   stage,
    //   isCounts
    //   }
    //   if(dateSelect.from && dateSelect.to){
    //   payload.dateSelect = dateSelect
    //   }
    // // console.log(campaignId)
    // console.warn(sortOptions);
    // const res = await axios.get(
    //   `${API_SERVICE_BACKEND}/campaignLeadsSearch/${campaignId}`,
    //   {
    //     params: payload,
    //     signal: abortController?.signal,
    //   }
    // );
    // if (res.status === 200) {
      return { leads: AllLeadData?.data?.leads, metaData: AllLeadData?.data?.metaData };
      // return { leads: res.data.data.leads, metaData: res.data.data.metaData };
    // } else {
    //   throw new Error(res.data);
    // }
  };
 
  getChartData = async (email, campaignIds, params) => {
    // console.log(campaignId)
    // const res = await axios.get(`${API_SERVICE_BACKEND}/dashboard-chart`, {
    //   params: { clientEmail: email, campaignIds, ...(params ? params : {}) },
    // });
    // if (res.status === 200) {
    //   return res.data.data;
    // } else {
    //   throw new Error(res.data);
    // }
    return DashboardChartDataStatic?.data;
  };

  updateCampaign = async (campaignId, payload) => {
    // console.log(campaignId, payload);
    // try {
      // const res = await axios.patch(`${API_SERVICE_BACKEND}/updateCampaign`, {
      //   id: campaignId,
      //   payload,
      // });
      // if (res.status === 204) {
        return true;
      // }
    // } catch (e) {
    //   console.log(e.message);
    // }
    // return false;
  };

  getLeadsTotalData = async (id,dateRange) => {
    return leadsTotalData?.data;
    // const res = await axios.post(`${API_SERVICE_BACKEND}/getLeadsTotalData`, {
    //   campaignIds : id,
    //   dateRange : dateRange
    // });
    // if (res.status === 200) {
    //   return res.data.data;
    // } else {
    //   throw new Error(res.data);
    // }
  }
}

export const jobsApi = new JobsApi();
