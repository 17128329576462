import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Stack,
  Skeleton,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

import { API_SERVICE_BACKEND } from "src/config";

export default function Invoices({ details }) {
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [seeData, setSeeData] = useState(false);

  const toggleSeeData = () => {
    setSeeData(!seeData);
  };

  const invoiceDatas = [
    {
      "id": "in_1QMwWGFrYeHPQNJAZ5whtXyS",
      "object": "invoice",
      "account_country": "US",
      "account_name": "B2B Rocket",
      "account_tax_ids": null,
      "amount_due": 100000,
      "amount_paid": 100000,
      "amount_remaining": 0,
      "amount_shipping": 0,
      "application": null,
      "application_fee_amount": null,
      "attempt_count": 1,
      "attempted": true,
      "auto_advance": false,
      "automatic_tax": {
        "enabled": false,
        "liability": null,
        "status": null
      },
      "automatically_finalizes_at": null,
      "billing_reason": "manual",
      "charge": "ch_3QMwXlFrYeHPQNJA0cn6cLgq",
      "collection_method": "send_invoice",
      "created": 1732042284,
      "currency": "usd",
      "custom_fields": null,
      "customer": "cus_RDyiUPd7TopBuW",
      "customer_address": null,
      "customer_email": "gm@uppteam.com",
      "customer_name": "Ganesh Marve",
      "customer_phone": null,
      "customer_shipping": null,
      "customer_tax_exempt": "none",
      "customer_tax_ids": [],
      "default_payment_method": null,
      "default_source": null,
      "default_tax_rates": [],
      "description": "Company: ISP Global Consulting Pvt Ltd. ( Uppteam is a trademark of ISP)\n\n\n$1,788 for Scale Annual ($1,000 to be paid now & remaining $788 to be auto-billed on the 4th month)",
      "discount": null,
      "discounts": [],
      "due_date": 1732647177,
      "effective_at": 1732042377,
      "ending_balance": 0,
      "footer": null,
      "from_invoice": null,
      "hosted_invoice_url": "https://invoice.stripe.com/i/acct_1MeO1iFrYeHPQNJA/live_YWNjdF8xTWVPMWlGclllSFBRTkpBLF9SRlJQSWRIbjEzM3RNMHBUUnFCNThrQmtvQmZRRUtDLDEyMjYyNjQ4Nw0200T0VsxRM5?s=ap",
      "invoice_pdf": "https://pay.stripe.com/invoice/acct_1MeO1iFrYeHPQNJA/live_YWNjdF8xTWVPMWlGclllSFBRTkpBLF9SRlJQSWRIbjEzM3RNMHBUUnFCNThrQmtvQmZRRUtDLDEyMjYyNjQ4Nw0200T0VsxRM5/pdf?s=ap",
      "issuer": {
        "type": "self"
      },
      "last_finalization_error": null,
      "latest_revision": null,
      "lines": {
        "object": "list",
        "data": [
          {
            "id": "il_1QMwXGFrYeHPQNJAtnXu0b2d",
            "object": "line_item",
            "amount": 100000,
            "amount_excluding_tax": 100000,
            "currency": "usd",
            "description": "Scale Plan ",
            "discount_amounts": [],
            "discountable": true,
            "discounts": [],
            "invoice": "in_1QMwWGFrYeHPQNJAZ5whtXyS",
            "invoice_item": "ii_1QMwXGFrYeHPQNJAGjoD0E6U",
            "livemode": true,
            "metadata": {},
            "period": {
              "end": 1732042346,
              "start": 1732042346
            },
            "plan": null,
            "pretax_credit_amounts": [],
            "price": {
              "id": "price_1QMwXGFrYeHPQNJALiH1uSF4",
              "object": "price",
              "active": false,
              "billing_scheme": "per_unit",
              "created": 1732042346,
              "currency": "usd",
              "custom_unit_amount": null,
              "livemode": true,
              "lookup_key": null,
              "metadata": {},
              "nickname": null,
              "product": "prod_Qzgh2udyMLF4ok",
              "recurring": null,
              "tax_behavior": "unspecified",
              "tiers_mode": null,
              "transform_quantity": null,
              "type": "one_time",
              "unit_amount": 100000,
              "unit_amount_decimal": "100000"
            },
            "proration": false,
            "proration_details": {
              "credited_items": null
            },
            "quantity": 1,
            "subscription": null,
            "tax_amounts": [],
            "tax_rates": [],
            "type": "invoiceitem",
            "unit_amount_excluding_tax": "100000"
          }
        ],
        "has_more": false,
        "total_count": 1,
        "url": "/v1/invoices/in_1QMwWGFrYeHPQNJAZ5whtXyS/lines"
      },
      "livemode": true,
      "metadata": {},
      "next_payment_attempt": null,
      "number": "BDA6299C-0003",
      "on_behalf_of": null,
      "paid": true,
      "paid_out_of_band": false,
      "payment_intent": "pi_3QMwXlFrYeHPQNJA0dd6GCfc",
      "payment_settings": {
        "default_mandate": null,
        "payment_method_options": null,
        "payment_method_types": null
      },
      "period_end": 1732042284,
      "period_start": 1732042284,
      "post_payment_credit_notes_amount": 0,
      "pre_payment_credit_notes_amount": 0,
      "quote": null,
      "receipt_number": "2845-7862",
      "rendering": {
        "amount_tax_display": null,
        "pdf": {
          "page_size": "letter"
        },
        "template": null,
        "template_version": null
      },
      "shipping_cost": null,
      "shipping_details": null,
      "starting_balance": 0,
      "statement_descriptor": null,
      "status": "paid",
      "status_transitions": {
        "finalized_at": 1732042377,
        "marked_uncollectible_at": null,
        "paid_at": 1732081928,
        "voided_at": null
      },
      "subscription": null,
      "subscription_details": {
        "metadata": null
      },
      "subtotal": 100000,
      "subtotal_excluding_tax": 100000,
      "tax": null,
      "test_clock": null,
      "total": 100000,
      "total_discount_amounts": [],
      "total_excluding_tax": 100000,
      "total_pretax_credit_amounts": [],
      "total_tax_amounts": [],
      "transfer_data": null,
      "webhooks_delivered_at": 1732042285
    },
    {
      "id": "in_1QMuFwFrYeHPQNJAD39T72l3",
      "object": "invoice",
      "account_country": "US",
      "account_name": "B2B Rocket",
      "account_tax_ids": null,
      "amount_due": 0,
      "amount_paid": 0,
      "amount_remaining": 0,
      "amount_shipping": 0,
      "application": null,
      "application_fee_amount": null,
      "attempt_count": 0,
      "attempted": true,
      "auto_advance": false,
      "automatic_tax": {
        "enabled": false,
        "liability": null,
        "status": null
      },
      "automatically_finalizes_at": null,
      "billing_reason": "manual",
      "charge": null,
      "collection_method": "send_invoice",
      "created": 1732033584,
      "currency": "usd",
      "custom_fields": null,
      "customer": "cus_RDyiUPd7TopBuW",
      "customer_address": null,
      "customer_email": "gm@uppteam.com",
      "customer_name": "Ganesh Marve",
      "customer_phone": null,
      "customer_shipping": null,
      "customer_tax_exempt": "none",
      "customer_tax_ids": [],
      "default_payment_method": null,
      "default_source": null,
      "default_tax_rates": [],
      "description": "Company: ISP Global Consulting Pvt Ltd. ( Uppteam is a trademark of ISP)\n\n\n$1,788 for Scale Annual ($1,000 to be paid now & remaining $788 to be auto-billed on the 4th month)",
      "discount": null,
      "discounts": [],
      "due_date": 1734625670,
      "effective_at": 1732033670,
      "ending_balance": 0,
      "footer": null,
      "from_invoice": null,
      "hosted_invoice_url": "https://invoice.stripe.com/i/acct_1MeO1iFrYeHPQNJA/live_YWNjdF8xTWVPMWlGclllSFBRTkpBLF9SRlA0VVprQnN3QXlLcTQzSW9RNVpmRGJmdkVudUU4LDEyMjYyNjQ4Nw0200rbA9aSlr?s=ap",
      "invoice_pdf": "https://pay.stripe.com/invoice/acct_1MeO1iFrYeHPQNJA/live_YWNjdF8xTWVPMWlGclllSFBRTkpBLF9SRlA0VVprQnN3QXlLcTQzSW9RNVpmRGJmdkVudUU4LDEyMjYyNjQ4Nw0200rbA9aSlr/pdf?s=ap",
      "issuer": {
        "type": "self"
      },
      "last_finalization_error": null,
      "latest_revision": null,
      "lines": {
        "object": "list",
        "data": [],
        "has_more": false,
        "total_count": 0,
        "url": "/v1/invoices/in_1QMuFwFrYeHPQNJAD39T72l3/lines"
      },
      "livemode": true,
      "metadata": {},
      "next_payment_attempt": null,
      "number": "BDA6299C-0002",
      "on_behalf_of": null,
      "paid": true,
      "paid_out_of_band": false,
      "payment_intent": null,
      "payment_settings": {
        "default_mandate": null,
        "payment_method_options": null,
        "payment_method_types": null
      },
      "period_end": 1732033584,
      "period_start": 1732033584,
      "post_payment_credit_notes_amount": 0,
      "pre_payment_credit_notes_amount": 0,
      "quote": null,
      "receipt_number": null,
      "rendering": {
        "amount_tax_display": null,
        "pdf": {
          "page_size": "letter"
        },
        "template": null,
        "template_version": null
      },
      "shipping_cost": null,
      "shipping_details": null,
      "starting_balance": 0,
      "statement_descriptor": null,
      "status": "paid",
      "status_transitions": {
        "finalized_at": 1732033670,
        "marked_uncollectible_at": null,
        "paid_at": 1732033670,
        "voided_at": null
      },
      "subscription": null,
      "subscription_details": {
        "metadata": null
      },
      "subtotal": 0,
      "subtotal_excluding_tax": 0,
      "tax": null,
      "test_clock": null,
      "total": 0,
      "total_discount_amounts": [],
      "total_excluding_tax": 0,
      "total_pretax_credit_amounts": [],
      "total_tax_amounts": [],
      "transfer_data": null,
      "webhooks_delivered_at": 1732033585
    },
    {
      "id": "in_1QMtRYFrYeHPQNJAME1c4PdX",
      "object": "invoice",
      "account_country": "US",
      "account_name": "B2B Rocket",
      "account_tax_ids": null,
      "amount_due": 250000,
      "amount_paid": 0,
      "amount_remaining": 250000,
      "amount_shipping": 0,
      "application": null,
      "application_fee_amount": null,
      "attempt_count": 0,
      "attempted": false,
      "auto_advance": true,
      "automatic_tax": {
        "enabled": false,
        "liability": null,
        "status": null
      },
      "automatically_finalizes_at": null,
      "billing_reason": "manual",
      "charge": null,
      "collection_method": "send_invoice",
      "created": 1732030460,
      "currency": "usd",
      "custom_fields": null,
      "customer": "cus_RFOEf2DH0DYyR5",
      "customer_address": null,
      "customer_email": "michael@thestitchnprintstore.com",
      "customer_name": null,
      "customer_phone": null,
      "customer_shipping": null,
      "customer_tax_exempt": "none",
      "customer_tax_ids": [],
      "default_payment_method": null,
      "default_source": null,
      "default_tax_rates": [],
      "description": "New User Implementation Scope of Work\n- Video call to verify user information.\n- Basic account setup configuration.\n- Video call to guide domain and mailbox purchases.\n- Setup up to 5 unique campaigns and lead lists.\n- Video call to review implementation and handoff.\n- Handoff document with a 6-month plan and troubleshooting guide.\n\nNote: Ongoing maintenance is not included.",
      "discount": null,
      "discounts": [],
      "due_date": 1734622539,
      "effective_at": 1732030539,
      "ending_balance": 0,
      "footer": null,
      "from_invoice": null,
      "hosted_invoice_url": "https://invoice.stripe.com/i/acct_1MeO1iFrYeHPQNJA/live_YWNjdF8xTWVPMWlGclllSFBRTkpBLF9SRk9FQktrQ2Z5cm5GZ0tNOHo1aHIyQ0Jwb3lIV3QwLDEyMjYyNjQ4Nw0200ezyNtz6I?s=ap",
      "invoice_pdf": "https://pay.stripe.com/invoice/acct_1MeO1iFrYeHPQNJA/live_YWNjdF8xTWVPMWlGclllSFBRTkpBLF9SRk9FQktrQ2Z5cm5GZ0tNOHo1aHIyQ0Jwb3lIV3QwLDEyMjYyNjQ4Nw0200ezyNtz6I/pdf?s=ap",
      "issuer": {
        "type": "self"
      },
      "last_finalization_error": null,
      "latest_revision": null,
      "lines": {
        "object": "list",
        "data": [
          {
            "id": "il_1QMtS8FrYeHPQNJABWkOMUjv",
            "object": "line_item",
            "amount": 0,
            "amount_excluding_tax": 0,
            "currency": "usd",
            "description": "Free Implementation ",
            "discount_amounts": [],
            "discountable": true,
            "discounts": [],
            "invoice": "in_1QMtRYFrYeHPQNJAME1c4PdX",
            "invoice_item": "ii_1QMtS8FrYeHPQNJAy43hhD4V",
            "livemode": true,
            "metadata": {},
            "period": {
              "end": 1732030496,
              "start": 1732030496
            },
            "plan": null,
            "pretax_credit_amounts": [],
            "price": {
              "id": "price_1QMtS8FrYeHPQNJAZv92RUqz",
              "object": "price",
              "active": false,
              "billing_scheme": "per_unit",
              "created": 1732030496,
              "currency": "usd",
              "custom_unit_amount": null,
              "livemode": true,
              "lookup_key": null,
              "metadata": {},
              "nickname": null,
              "product": "prod_RFOEyghiXaigvH",
              "recurring": null,
              "tax_behavior": "unspecified",
              "tiers_mode": null,
              "transform_quantity": null,
              "type": "one_time",
              "unit_amount": 0,
              "unit_amount_decimal": "0"
            },
            "proration": false,
            "proration_details": {
              "credited_items": null
            },
            "quantity": 1,
            "subscription": null,
            "tax_amounts": [],
            "tax_rates": [],
            "type": "invoiceitem",
            "unit_amount_excluding_tax": "0"
          },
          {
            "id": "il_1QMtRuFrYeHPQNJAEg8zx7bF",
            "object": "line_item",
            "amount": 250000,
            "amount_excluding_tax": 250000,
            "currency": "usd",
            "description": "Unlimited Plan (Upgraded from Scale Promo) ",
            "discount_amounts": [],
            "discountable": true,
            "discounts": [],
            "invoice": "in_1QMtRYFrYeHPQNJAME1c4PdX",
            "invoice_item": "ii_1QMtRuFrYeHPQNJAHuouK5wt",
            "livemode": true,
            "metadata": {},
            "period": {
              "end": 1732030482,
              "start": 1732030482
            },
            "plan": null,
            "pretax_credit_amounts": [],
            "price": {
              "id": "price_1QMtRuFrYeHPQNJAeAUArzR6",
              "object": "price",
              "active": false,
              "billing_scheme": "per_unit",
              "created": 1732030482,
              "currency": "usd",
              "custom_unit_amount": null,
              "livemode": true,
              "lookup_key": null,
              "metadata": {},
              "nickname": null,
              "product": "prod_RFOEwwY2aU3wqt",
              "recurring": null,
              "tax_behavior": "unspecified",
              "tiers_mode": null,
              "transform_quantity": null,
              "type": "one_time",
              "unit_amount": 250000,
              "unit_amount_decimal": "250000"
            },
            "proration": false,
            "proration_details": {
              "credited_items": null
            },
            "quantity": 1,
            "subscription": null,
            "tax_amounts": [],
            "tax_rates": [],
            "type": "invoiceitem",
            "unit_amount_excluding_tax": "250000"
          }
        ],
        "has_more": false,
        "total_count": 2,
        "url": "/v1/invoices/in_1QMtRYFrYeHPQNJAME1c4PdX/lines"
      },
      "livemode": true,
      "metadata": {},
      "next_payment_attempt": null,
      "number": "1359AFE2-0001",
      "on_behalf_of": null,
      "paid": false,
      "paid_out_of_band": false,
      "payment_intent": "pi_3QMtSpFrYeHPQNJA022J70aB",
      "payment_settings": {
        "default_mandate": null,
        "payment_method_options": null,
        "payment_method_types": null
      },
      "period_end": 1732030460,
      "period_start": 1732030460,
      "post_payment_credit_notes_amount": 0,
      "pre_payment_credit_notes_amount": 0,
      "quote": null,
      "receipt_number": null,
      "rendering": {
        "amount_tax_display": null,
        "pdf": {
          "page_size": "letter"
        },
        "template": null,
        "template_version": null
      },
      "shipping_cost": null,
      "shipping_details": null,
      "starting_balance": 0,
      "statement_descriptor": null,
      "status": "open",
      "status_transitions": {
        "finalized_at": 1732030539,
        "marked_uncollectible_at": null,
        "paid_at": null,
        "voided_at": null
      },
      "subscription": null,
      "subscription_details": {
        "metadata": null
      },
      "subtotal": 250000,
      "subtotal_excluding_tax": 250000,
      "tax": null,
      "test_clock": null,
      "total": 250000,
      "total_discount_amounts": [],
      "total_excluding_tax": 250000,
      "total_pretax_credit_amounts": [],
      "total_tax_amounts": [],
      "transfer_data": null,
      "webhooks_delivered_at": 1732030461
    },
    {
      "id": "in_1QMsmHFrYeHPQNJAExQPrNT4",
      "object": "invoice",
      "account_country": "US",
      "account_name": "B2B Rocket",
      "account_tax_ids": null,
      "amount_due": 15750,
      "amount_paid": 15750,
      "amount_remaining": 0,
      "amount_shipping": 0,
      "application": null,
      "application_fee_amount": null,
      "attempt_count": 1,
      "attempted": true,
      "auto_advance": false,
      "automatic_tax": {
        "enabled": false,
        "liability": null,
        "status": null
      },
      "automatically_finalizes_at": null,
      "billing_reason": "subscription_cycle",
      "charge": "ch_3QMtjDFrYeHPQNJA0w3YgzS2",
      "collection_method": "charge_automatically",
      "created": 1732027901,
      "currency": "usd",
      "custom_fields": null,
      "customer": "cus_QkkBAlc64lyFNn",
      "customer_address": null,
      "customer_email": "nnbhakta@proplate.com",
      "customer_name": null,
      "customer_phone": null,
      "customer_shipping": null,
      "customer_tax_exempt": "none",
      "customer_tax_ids": [],
      "default_payment_method": null,
      "default_source": null,
      "default_tax_rates": [],
      "description": null,
      "discount": null,
      "discounts": [],
      "due_date": null,
      "effective_at": 1732031553,
      "ending_balance": 0,
      "footer": null,
      "from_invoice": null,
      "hosted_invoice_url": "https://invoice.stripe.com/i/acct_1MeO1iFrYeHPQNJA/live_YWNjdF8xTWVPMWlGclllSFBRTkpBLF9SRk5YSU55SnhNQ21leDZVVWNIeHNSZUsxTENCQzJPLDEyMjYyNjQ4Nw0200kqB8tkwI?s=ap",
      "invoice_pdf": "https://pay.stripe.com/invoice/acct_1MeO1iFrYeHPQNJA/live_YWNjdF8xTWVPMWlGclllSFBRTkpBLF9SRk5YSU55SnhNQ21leDZVVWNIeHNSZUsxTENCQzJPLDEyMjYyNjQ4Nw0200kqB8tkwI/pdf?s=ap",
      "issuer": {
        "type": "self"
      },
      "last_finalization_error": null,
      "latest_revision": null,
      "lines": {
        "object": "list",
        "data": [
          {
            "id": "il_1QMsmHFrYeHPQNJA3DrSQilR",
            "object": "line_item",
            "amount": 15750,
            "amount_excluding_tax": 15750,
            "currency": "usd",
            "description": "45 × Email (at $3.50 / month)",
            "discount_amounts": [],
            "discountable": true,
            "discounts": [],
            "invoice": "in_1QMsmHFrYeHPQNJAExQPrNT4",
            "livemode": true,
            "metadata": {
              "subscriptionType": "email"
            },
            "period": {
              "end": 1734619877,
              "start": 1732027877
            },
            "plan": {
              "id": "price_1PqXcTFrYeHPQNJAf9Nu0ntX",
              "object": "plan",
              "active": true,
              "aggregate_usage": null,
              "amount": 350,
              "amount_decimal": "350",
              "billing_scheme": "per_unit",
              "created": 1724320073,
              "currency": "usd",
              "interval": "month",
              "interval_count": 1,
              "livemode": true,
              "metadata": {},
              "meter": null,
              "nickname": null,
              "product": "prod_QhxXsLw6T2iacj",
              "tiers_mode": null,
              "transform_usage": null,
              "trial_period_days": null,
              "usage_type": "licensed"
            },
            "pretax_credit_amounts": [],
            "price": {
              "id": "price_1PqXcTFrYeHPQNJAf9Nu0ntX",
              "object": "price",
              "active": true,
              "billing_scheme": "per_unit",
              "created": 1724320073,
              "currency": "usd",
              "custom_unit_amount": null,
              "livemode": true,
              "lookup_key": null,
              "metadata": {},
              "nickname": null,
              "product": "prod_QhxXsLw6T2iacj",
              "recurring": {
                "aggregate_usage": null,
                "interval": "month",
                "interval_count": 1,
                "meter": null,
                "trial_period_days": null,
                "usage_type": "licensed"
              },
              "tax_behavior": "unspecified",
              "tiers_mode": null,
              "transform_quantity": null,
              "type": "recurring",
              "unit_amount": 350,
              "unit_amount_decimal": "350"
            },
            "proration": false,
            "proration_details": {
              "credited_items": null
            },
            "quantity": 45,
            "subscription": "sub_1Q0lhSFrYeHPQNJAb5eQ41pU",
            "subscription_item": "si_QsWkWmyYYdYtmp",
            "tax_amounts": [],
            "tax_rates": [],
            "type": "subscription",
            "unit_amount_excluding_tax": "350"
          }
        ],
        "has_more": false,
        "total_count": 1,
        "url": "/v1/invoices/in_1QMsmHFrYeHPQNJAExQPrNT4/lines"
      },
      "livemode": true,
      "metadata": {},
      "next_payment_attempt": null,
      "number": "4456A524-0006",
      "on_behalf_of": null,
      "paid": true,
      "paid_out_of_band": false,
      "payment_intent": "pi_3QMtjDFrYeHPQNJA06DxHjL5",
      "payment_settings": {
        "default_mandate": null,
        "payment_method_options": null,
        "payment_method_types": null
      },
      "period_end": 1732027877,
      "period_start": 1729349477,
      "post_payment_credit_notes_amount": 0,
      "pre_payment_credit_notes_amount": 0,
      "quote": null,
      "receipt_number": "2311-9376",
      "rendering": {
        "amount_tax_display": null,
        "pdf": null,
        "template": "inrtem_1Q5QCcFrYeHPQNJATfpXmfOK",
        "template_version": 1
      },
      "shipping_cost": null,
      "shipping_details": null,
      "starting_balance": 0,
      "statement_descriptor": null,
      "status": "paid",
      "status_transitions": {
        "finalized_at": 1732031553,
        "marked_uncollectible_at": null,
        "paid_at": 1732031553,
        "voided_at": null
      },
      "subscription": "sub_1Q0lhSFrYeHPQNJAb5eQ41pU",
      "subscription_details": {
        "metadata": {
          "subscriptionType": "email"
        }
      },
      "subtotal": 15750,
      "subtotal_excluding_tax": 15750,
      "tax": null,
      "test_clock": null,
      "total": 15750,
      "total_discount_amounts": [],
      "total_excluding_tax": 15750,
      "total_pretax_credit_amounts": [],
      "total_tax_amounts": [],
      "transfer_data": null,
      "webhooks_delivered_at": 1732027903
    },
    {
      "id": "in_1QMe68FrYeHPQNJAwamMqcAm",
      "object": "invoice",
      "account_country": "US",
      "account_name": "B2B Rocket",
      "account_tax_ids": null,
      "amount_due": 70000,
      "amount_paid": 70000,
      "amount_remaining": 0,
      "amount_shipping": 0,
      "application": null,
      "application_fee_amount": null,
      "attempt_count": 1,
      "attempted": true,
      "auto_advance": false,
      "automatic_tax": {
        "enabled": false,
        "liability": null,
        "status": null
      },
      "automatically_finalizes_at": null,
      "billing_reason": "manual",
      "charge": "ch_3QMe9vFrYeHPQNJA1OXL9Vau",
      "collection_method": "send_invoice",
      "created": 1731971472,
      "currency": "usd",
      "custom_fields": null,
      "customer": "cus_QnzFWh8caHDCT1",
      "customer_address": {
        "city": null,
        "country": "GB",
        "line1": null,
        "line2": null,
        "postal_code": "EH33 1HD",
        "state": null
      },
      "customer_email": "patrick.hughes@weber.co.uk",
      "customer_name": "Patrick Hughes",
      "customer_phone": null,
      "customer_shipping": null,
      "customer_tax_exempt": "none",
      "customer_tax_ids": [],
      "default_payment_method": null,
      "default_source": null,
      "default_tax_rates": [],
      "description": "\nNew User Implementation Scope of Work\n- Video call to verify user information.\n- Basic account setup configuration.\n- Video call to guide domain and mailbox purchases.\n- Setup up to 5 unique campaigns and lead lists.\n- Video call to review implementation and handoff.\n- Handoff document with a 6-month plan and troubleshooting guide.\n\nNote: Ongoing maintenance is not included.",
      "discount": null,
      "discounts": [],
      "due_date": 1732058107,
      "effective_at": 1731971707,
      "ending_balance": 0,
      "footer": null,
      "from_invoice": null,
      "hosted_invoice_url": "https://invoice.stripe.com/i/acct_1MeO1iFrYeHPQNJA/live_YWNjdF8xTWVPMWlGclllSFBRTkpBLF9SRjhNQjVYdWtmNjVHa3Q1Nk9PZ2xwSE42aUFRQlpYLDEyMjYyNjQ4Nw0200rpgNZMUY?s=ap",
      "invoice_pdf": "https://pay.stripe.com/invoice/acct_1MeO1iFrYeHPQNJA/live_YWNjdF8xTWVPMWlGclllSFBRTkpBLF9SRjhNQjVYdWtmNjVHa3Q1Nk9PZ2xwSE42aUFRQlpYLDEyMjYyNjQ4Nw0200rpgNZMUY/pdf?s=ap",
      "issuer": {
        "type": "self"
      },
      "last_finalization_error": null,
      "latest_revision": null,
      "lines": {
        "object": "list",
        "data": [
          {
            "id": "il_1QMe6KFrYeHPQNJA8i1f6Leq",
            "object": "line_item",
            "amount": 70000,
            "amount_excluding_tax": 70000,
            "currency": "usd",
            "description": "Implementation Program ",
            "discount_amounts": [],
            "discountable": true,
            "discounts": [],
            "invoice": "in_1QMe68FrYeHPQNJAwamMqcAm",
            "invoice_item": "ii_1QMe6KFrYeHPQNJAdfiJq9Xd",
            "livemode": true,
            "metadata": {},
            "period": {
              "end": 1731971484,
              "start": 1731971484
            },
            "plan": null,
            "pretax_credit_amounts": [],
            "price": {
              "id": "price_1QMe6KFrYeHPQNJAZTtPgQzd",
              "object": "price",
              "active": false,
              "billing_scheme": "per_unit",
              "created": 1731971484,
              "currency": "usd",
              "custom_unit_amount": null,
              "livemode": true,
              "lookup_key": null,
              "metadata": {},
              "nickname": null,
              "product": "prod_RF8NgdffOYXbyB",
              "recurring": null,
              "tax_behavior": "unspecified",
              "tiers_mode": null,
              "transform_quantity": null,
              "type": "one_time",
              "unit_amount": 70000,
              "unit_amount_decimal": "70000"
            },
            "proration": false,
            "proration_details": {
              "credited_items": null
            },
            "quantity": 1,
            "subscription": null,
            "tax_amounts": [],
            "tax_rates": [],
            "type": "invoiceitem",
            "unit_amount_excluding_tax": "70000"
          }
        ],
        "has_more": false,
        "total_count": 1,
        "url": "/v1/invoices/in_1QMe68FrYeHPQNJAwamMqcAm/lines"
      },
      "livemode": true,
      "metadata": {},
      "next_payment_attempt": null,
      "number": "A1DAEE6E-0006",
      "on_behalf_of": null,
      "paid": true,
      "paid_out_of_band": false,
      "payment_intent": "pi_3QMe9vFrYeHPQNJA1PhU5tlB",
      "payment_settings": {
        "default_mandate": null,
        "payment_method_options": null,
        "payment_method_types": [
          "card",
          "cashapp",
          "link"
        ]
      },
      "period_end": 1732911355,
      "period_start": 1730232955,
      "post_payment_credit_notes_amount": 0,
      "pre_payment_credit_notes_amount": 0,
      "quote": null,
      "receipt_number": "2502-6301",
      "rendering": {
        "amount_tax_display": null,
        "pdf": {
          "page_size": "letter"
        },
        "template": "inrtem_1Q5QCcFrYeHPQNJATfpXmfOK",
        "template_version": 1
      },
      "shipping_cost": null,
      "shipping_details": null,
      "starting_balance": 0,
      "statement_descriptor": null,
      "status": "paid",
      "status_transitions": {
        "finalized_at": 1731971707,
        "marked_uncollectible_at": null,
        "paid_at": 1732008900,
        "voided_at": null
      },
      "subscription": null,
      "subscription_details": {
        "metadata": null
      },
      "subtotal": 70000,
      "subtotal_excluding_tax": 70000,
      "tax": null,
      "test_clock": null,
      "total": 70000,
      "total_discount_amounts": [],
      "total_excluding_tax": 70000,
      "total_pretax_credit_amounts": [],
      "total_tax_amounts": [],
      "transfer_data": null,
      "webhooks_delivered_at": 1731971473
    }
  ]

  const getInvoiceData = async (limit) => {
    try {
      setIsLoading(true);
      // const res = await axios.post(`${API_SERVICE_BACKEND}/getInvoice`, {
      //   customerId: details.customerId,
      //   limit: limit || 5,
      // });
      const res = {data: invoiceDatas}
      if (res) {
        setInvoiceData(res.data);
      }
      toggleSeeData();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (details) {
      getInvoiceData();
    }
  }, [details]);

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }

  return (
    <>
      {isLoading ? (
        <Stack spacing={2}>
          <Skeleton variant="text" width={150} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
        </Stack>
      ) : (
        details &&
        details.customerId && (
          <Stack spacing={2}>
            <Typography variant="h6" component="h2" sx={{ paddingTop: 2 }}>
              Invoice List
            </Typography>
            <TableContainer component={Paper} elevation={2}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width="10%">Invoice</TableCell>
                    <TableCell align="right" width="20%">
                      Amount Due
                    </TableCell>
                    <TableCell align="right" width="20%">
                      Amount Paid
                    </TableCell>
                    <TableCell align="right" width="30%">
                      Invoice Date
                    </TableCell>
                    <TableCell align="center" width="40%">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceData.length ? (
                    invoiceData.map((e) => (
                      <TableRow key={e.id}>
                        <TableCell>
                          <IconButton
                            component="a"
                            href={`${e?.invoice_pdf}`}
                            color="primary"
                            aria-label="view invoice PDF"
                            target="_blank"
                          >
                            <img
                              src="/assets/pdf.svg"
                              alt="View Invoice"
                              width={30}
                              height={30}
                            />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body1" fontWeight="bold">
                            {currencyFormatter.format(
                              Math.abs(e?.amount_due / 100)
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body1" fontWeight="bold">
                            {currencyFormatter.format(
                              Math.abs(e?.amount_paid / 100)
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body1">
                            {formatTimestamp(e?.created)}
                            {/* {formatTimestamp(e?.lines?.data[0]?.period?.start)} */}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <IconButton
                              component="a"
                              href={`${e?.invoice_pdf}`}
                              aria-label="download invoice"
                              target="_blank"
                            >
                              <DownloadIcon sx={{ color: "#5761FE" }} />
                            </IconButton>
                            <Button
                              variant="contained"
                              href={`${e?.hosted_invoice_url}`}
                              sx={{ marginLeft: 1 }}
                              target="_blank"
                              style={{
                                backgroundColor: "#5761FE",
                                fontSize: "14px",
                              }}
                            >
                              View
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            textAlign: "center",
                            paddingY: "10px",
                          }}
                        >
                          No Invoices Found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {invoiceData.length ? (
              <Box mt={3} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#5761FE" }}
                  onClick={() => {
                    getInvoiceData(seeData ? 100 : 5);
                  }}
                >
                  {seeData ? "See More" : "See Less"}
                </Button>
              </Box>
            ) : (
              ""
            )}
          </Stack>
        )
      )}
    </>
  );
}
